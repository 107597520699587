<template>
  <div class="index">
    <div data-server-rendered="true" id="__nuxt">
      <!---->
      <div id="__layout">
        <div class="full-wrap">
         <headerCom :aboutUs="aboutUs" :isactive="isactive"></headerCom>
          <div class="about-us" data-v-1d7ec599>
            <!-- <div class="moblie-banner" data-v-1d7ec599>
              <img src="../../assets/picture/05a1ad2.png" alt data-v-1d7ec599 />
            </div> -->
            <!-- <div class="mobile-us-nav" data-v-1d7ec599>
              <div class="moblie-us-wrap" data-v-1d7ec599>
                <a
                  href="#company"
                  class="m-u-w-conpany m-u-w-conpany-active"
                  data-v-1d7ec599
                  >公司介绍</a
                >
                <a class="line" data-v-1d7ec599></a>
                <a href="#history" class="m-u-w-history" data-v-1d7ec599
                  >公司发展历程</a
                >
              </div>
            </div> -->
            <div class="ab-us-wrap" data-v-1d7ec599>
              <div id="companynew" class="slid-left-bar" data-v-1d7ec599>
                <ul class="slide-left-wrap" data-v-1d7ec599>
                  <li data-v-1d7ec599>
                    <a href="#company" data-v-1d7ec599>公司介绍</a>
                  </li>
                  <li data-v-1d7ec599>
                    <a href="#honour" data-v-1d7ec599>企业文化</a>
                  </li>
                  <!-- <li data-v-1d7ec599>
                    <a href="#history" data-v-1d7ec599>发展历程</a>
                  </li>
                  <li data-v-1d7ec599>
                    <a href="#aboutus" data-v-1d7ec599>联系我们</a>
                  </li> -->
                </ul>
              </div>
              <div id="company" class="slide-right-wrap" data-v-1d7ec599>
               
                <div class="ab-company" data-v-1d7ec599>
                  <h1 class="ab-title" data-v-1d7ec599>公司介绍</h1>
                  <p class="ab-desc" data-v-1d7ec599>
                   重庆沁薪科技有限公司（以下简称“沁薪科技”）是H.R.B人力资源蓝领服务共享平台旗下的互联网公司。公司立足于人力资源外包行业，旨在为人力资源外包行业提供数字化升级，助力行业发展。
					公司自主构建了薪生活服务平台，通过数字化、信息化手段，为人力资源公司提供技术支持，帮助人力资源公司解决自身痛点，降低成本，提高工作效率，实现可视化人力资源外包服务。
					与此同时，沁薪科技持续关注蓝领人群的生活水平及生活质量，秉承为蓝领提供高性价比生活服务，围绕蓝领人群“衣、食、住、行、娱”等维度，通过建立“无薪消费”、“社群电商”、“金融服务”等方式，降低蓝领人群的生活成本，提升蓝领人群的生活品质，为蓝领人群建立一个属于自己的线上生活舒适圈。
				  </p>
                </div>
                <div class="team-view" id="honour" data-v-1d7ec599>
                  <h1 class="ab-title" data-v-1d7ec599>企业文化</h1>
                  <div class="team-content" data-v-1d7ec599>
                    <div>
						<p><b>使命：</b></p>
						<p class="text-indent">助力中国人力资源行业数字化变革，为中国制造赋能。</p>
						<p class="mgt15"><b>愿景：</b></p>
						<p class="text-indent">成为最会做劳务的互联网公司、成为最会做互联网界的劳务公司。做人力资源行业数字化领导者。</p>
						<p class="mgt15"><b>核心价值观：</b></p>
						<p class="text-indent">助力企业--为企业发展提供优选人才</p>
						<p class="text-indent">惠达蓝领--为蓝领打造线上优享生活圈</p>
						<p class="text-indent">幸福员工--为员工提供展现自我舞台</p>
						<p class="mgt15"><b>企业精神：</b></p>
						<p class="text-indent">沁薪人要像发动机那样，认真敬业</p>
						<p class="text-indent">沁薪人要像发动机那样，充满激情</p>
						<p class="text-indent">沁薪人要像发动机那样，团结协作</p>
						<p class="text-indent">沁薪人要像发动机那样，令行禁止</p>
						<p class="mgt15"><b>服务理念：</b></p>
						<p class="text-indent">客户至上--以客户的需求为出发点</p>
						<p class="text-indent">真诚待人--对待客户像对待自己家人一样</p>
						<p class="text-indent">诚信为本--成为客户可信赖、可依赖的伙伴</p>
						<p class="text-indent">助力成功--客户满意是我们永恒的追求</p>
						<p class="mgt15"><b>团队理念：</b></p>
						<p class="text-indent">目标同向--知晓发展方向，认同企业文化，明晰奋斗目标。</p>
						<p class="text-indent">负重领先--肩负公司发展使命，勇于承担，敢于拼搏向前。</p>
						<p class="text-indent">默契信任--互帮互助，相互配合，彼此信任，携手共进。</p>
						<p class="text-indent">同享共担--收获同享，责任共担，牢牢拧成一股绳。</p>
					</div>
                  </div>
                </div>
               
              </div>
            </div>
          </div>
         
          <div class="footer-img">
            <div class="pdt-20">
                    <a href="https://beian.miit.gov.cn/" target="blank">【工信部ICP备案】</a>
                    <a href="https://beian.miit.gov.cn/" target="blank">渝ICP备2021012534号</a>
                  </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import headerCom from "../../components/header-com.vue"
export default {
  props: [],
  components: {
	  headerCom
  },
  data() {
    return {
      isScroll: false,
	  aboutUs: true,
	  isactive:1,
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.ls-animation > * {
  transform: perspective(400px) rotateX(90deg);
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  opacity: 0;
}

.ls-animationed > * {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: fadeInUp2;
  animation-name: fadeInUp2;
}

.ls-animationed > * :nth-of-type(5n + 1) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.ls-animationed > * :nth-of-type(5n + 2) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.ls-animationed > * :nth-of-type(5n + 3) {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.ls-animationed > * :nth-of-type(5n + 4) {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.ls-animationed > * :nth-of-type(5n) {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

@-webkit-keyframes fadeInUp2 {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

@keyframes fadeInUp2 {
  0% {
    opacity: 0;
    transform: translate3d(0, 50%, 0);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

@-webkit-keyframes flipInX {
  0% {
    transform: perspective(400px) rotateX(90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    transform: perspective(400px) rotateX(-20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  60% {
    transform: perspective(400px) rotateX(10deg);
    opacity: 1;
  }

  80% {
    transform: perspective(400px) rotateX(-5deg);
  }

  to {
    transform: perspective(400px);
    opacity: 1;
  }
}

@keyframes flipInX {
  0% {
    transform: perspective(400px) rotateX(90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    transform: perspective(400px) rotateX(-20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  60% {
    transform: perspective(400px) rotateX(10deg);
    opacity: 1;
  }

  80% {
    transform: perspective(400px) rotateX(-5deg);
  }

  to {
    transform: perspective(400px);
    opacity: 1;
  }
}

.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
}

.swiper-container-no-flexbox .swiper-slide {
  float: left;
}

.swiper-container-vertical > .swiper-wrapper {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.swiper-container-android .swiper-slide,
.swiper-wrapper {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.swiper-container-multirow > .swiper-wrapper {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.swiper-container-free-mode > .swiper-wrapper {
  -webkit-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  margin: 0 auto;
}

.swiper-slide {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}

.swiper-slide-invisible-blank {
  visibility: hidden;
}

.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
  height: auto;
}

.swiper-container-autoheight .swiper-wrapper {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-transition-property: height, -webkit-transform;
  transition-property: height, -webkit-transform;
  -o-transition-property: transform, height;
  transition-property: transform, height;
  transition-property: transform, height, -webkit-transform;
}

.swiper-container-3d {
  -webkit-perspective: 1200px;
  perspective: 1200px;
}

.swiper-container-3d .swiper-cube-shadow,
.swiper-container-3d .swiper-slide,
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-wrapper {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
}

.swiper-container-3d .swiper-slide-shadow-left {
  background-image: -webkit-gradient(
    linear,
    right top,
    left top,
    from(rgba(0, 0, 0, 0.5)),
    to(transparent)
  );
  background-image: -webkit-linear-gradient(
    right,
    rgba(0, 0, 0, 0.5),
    transparent
  );
  background-image: -o-linear-gradient(right, rgba(0, 0, 0, 0.5), transparent);
  background-image: linear-gradient(270deg, rgba(0, 0, 0, 0.5), transparent);
}

.swiper-container-3d .swiper-slide-shadow-right {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(0, 0, 0, 0.5)),
    to(transparent)
  );
  background-image: -webkit-linear-gradient(
    left,
    rgba(0, 0, 0, 0.5),
    transparent
  );
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5), transparent);
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.5), transparent);
}

.swiper-container-3d .swiper-slide-shadow-top {
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(rgba(0, 0, 0, 0.5)),
    to(transparent)
  );
  background-image: -webkit-linear-gradient(
    bottom,
    rgba(0, 0, 0, 0.5),
    transparent
  );
  background-image: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.5), transparent);
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5), transparent);
}

.swiper-container-3d .swiper-slide-shadow-bottom {
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 0.5)),
    to(transparent)
  );
  background-image: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0.5),
    transparent
  );
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.5), transparent);
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5), transparent);
}

.swiper-container-wp8-horizontal,
.swiper-container-wp8-horizontal > .swiper-wrapper {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.swiper-container-wp8-vertical,
.swiper-container-wp8-vertical > .swiper-wrapper {
  -ms-touch-action: pan-x;
  touch-action: pan-x;
}

.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  background-size: 27px 44px;
  background-position: 50%;
  background-repeat: no-repeat;
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M0 22L22 0l2.1 2.1L4.2 22l19.9 19.9L22 44 0 22z' fill='%23007aff'/%3E%3C/svg%3E");
  left: 10px;
  right: auto;
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M27 22L5 44l-2.1-2.1L22.8 22 2.9 2.1 5 0l22 22z' fill='%23007aff'/%3E%3C/svg%3E");
  right: 10px;
  left: auto;
}

.swiper-button-prev.swiper-button-white,
.swiper-container-rtl .swiper-button-next.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M0 22L22 0l2.1 2.1L4.2 22l19.9 19.9L22 44 0 22z' fill='%23fff'/%3E%3C/svg%3E");
}

.swiper-button-next.swiper-button-white,
.swiper-container-rtl .swiper-button-prev.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M27 22L5 44l-2.1-2.1L22.8 22 2.9 2.1 5 0l22 22z' fill='%23fff'/%3E%3C/svg%3E");
}

.swiper-button-prev.swiper-button-black,
.swiper-container-rtl .swiper-button-next.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M0 22L22 0l2.1 2.1L4.2 22l19.9 19.9L22 44 0 22z'/%3E%3C/svg%3E");
}

.swiper-button-next.swiper-button-black,
.swiper-container-rtl .swiper-button-prev.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M27 22L5 44l-2.1-2.1L22.8 22 2.9 2.1 5 0l22 22z'/%3E%3C/svg%3E");
}

.swiper-button-lock {
  display: none;
}

.swiper-pagination {
  position: absolute;
  text-align: center;
  -webkit-transition: opacity 0.3s;
  -o-transition: 0.3s opacity;
  transition: opacity 0.3s;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  z-index: 10;
}

.swiper-pagination.swiper-pagination-hidden {
  opacity: 0;
}

.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 10px;
  left: 0;
  width: 100%;
}

.swiper-pagination-bullets-dynamic {
  overflow: hidden;
  font-size: 0;
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  -webkit-transform: scale(0.33);
  -ms-transform: scale(0.33);
  transform: scale(0.33);
  position: relative;
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active,
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
  -webkit-transform: scale(0.66);
  -ms-transform: scale(0.66);
  transform: scale(0.66);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
  -webkit-transform: scale(0.33);
  -ms-transform: scale(0.33);
  transform: scale(0.33);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
  -webkit-transform: scale(0.66);
  -ms-transform: scale(0.66);
  transform: scale(0.66);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
  -webkit-transform: scale(0.33);
  -ms-transform: scale(0.33);
  transform: scale(0.33);
}

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: #000;
  opacity: 0.2;
}

button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #007aff;
}

.swiper-container-vertical > .swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  -webkit-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
}

.swiper-container-vertical
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 6px 0;
  display: block;
}

.swiper-container-vertical
  > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 8px;
}

.swiper-container-vertical
  > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet {
  display: inline-block;
  -webkit-transition: top 0.2s, -webkit-transform 0.2s;
  transition: top 0.2s, -webkit-transform 0.2s;
  -o-transition: 0.2s transform, 0.2s top;
  transition: transform 0.2s, top 0.2s;
  transition: transform 0.2s, top 0.2s, -webkit-transform 0.2s;
}

.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 4px;
}

.swiper-container-horizontal
  > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  white-space: nowrap;
}

.swiper-container-horizontal
  > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet {
  -webkit-transition: left 0.2s, -webkit-transform 0.2s;
  transition: left 0.2s, -webkit-transform 0.2s;
  -o-transition: 0.2s transform, 0.2s left;
  transition: transform 0.2s, left 0.2s;
  transition: transform 0.2s, left 0.2s, -webkit-transform 0.2s;
}

.swiper-container-horizontal.swiper-container-rtl
  > .swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet {
  -webkit-transition: right 0.2s, -webkit-transform 0.2s;
  transition: right 0.2s, -webkit-transform 0.2s;
  -o-transition: 0.2s transform, 0.2s right;
  transition: transform 0.2s, right 0.2s;
  transition: transform 0.2s, right 0.2s, -webkit-transform 0.2s;
}

.swiper-pagination-progressbar {
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: #007aff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: left top;
  -ms-transform-origin: left top;
  transform-origin: left top;
}

.swiper-container-rtl
  .swiper-pagination-progressbar
  .swiper-pagination-progressbar-fill {
  -webkit-transform-origin: right top;
  -ms-transform-origin: right top;
  transform-origin: right top;
}

.swiper-container-horizontal > .swiper-pagination-progressbar,
.swiper-container-vertical
  > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
  width: 100%;
  height: 4px;
  left: 0;
  top: 0;
}

.swiper-container-horizontal
  > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite,
.swiper-container-vertical > .swiper-pagination-progressbar {
  width: 4px;
  height: 100%;
  left: 0;
  top: 0;
}

.swiper-pagination-white .swiper-pagination-bullet-active {
  background: #fff;
}

.swiper-pagination-progressbar.swiper-pagination-white {
  background: hsla(0, 0%, 100%, 0.25);
}

.swiper-pagination-progressbar.swiper-pagination-white
  .swiper-pagination-progressbar-fill {
  background: #fff;
}

.swiper-pagination-black .swiper-pagination-bullet-active {
  background: #000;
}

.swiper-pagination-progressbar.swiper-pagination-black {
  background: rgba(0, 0, 0, 0.25);
}

.swiper-pagination-progressbar.swiper-pagination-black
  .swiper-pagination-progressbar-fill {
  background: #000;
}

.swiper-pagination-lock {
  display: none;
}

.swiper-scrollbar {
  border-radius: 10px;
  position: relative;
  -ms-touch-action: none;
  background: rgba(0, 0, 0, 0.1);
}

.swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
  left: 1%;
  bottom: 3px;
  z-index: 50;
  height: 5px;
  width: 98%;
}

.swiper-container-vertical > .swiper-scrollbar {
  position: absolute;
  right: 3px;
  top: 1%;
  z-index: 50;
  width: 5px;
  height: 98%;
}

.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  left: 0;
  top: 0;
}

.swiper-scrollbar-cursor-drag {
  cursor: move;
}

.swiper-scrollbar-lock {
  display: none;
}

.swiper-zoom-container {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.swiper-zoom-container > canvas,
.swiper-zoom-container > img,
.swiper-zoom-container > svg {
  max-width: 100%;
  max-height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.swiper-slide-zoomed {
  cursor: move;
}

.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  -webkit-transform-origin: 50%;
  -ms-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-animation: swiper-preloader-spin 1s steps(12) infinite;
  animation: swiper-preloader-spin 1s steps(12) infinite;
}

.swiper-lazy-preloader:after {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 120 120' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' stroke='%236c6c6c' stroke-width='11' stroke-linecap='round' d='M60 7v20'/%3E%3C/defs%3E%3Cuse xlink:href='%23a' opacity='.27'/%3E%3Cuse xlink:href='%23a' opacity='.27' transform='rotate(30 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.27' transform='rotate(60 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.27' transform='rotate(90 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.27' transform='rotate(120 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.27' transform='rotate(150 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.37' transform='rotate(180 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.46' transform='rotate(210 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.56' transform='rotate(240 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.66' transform='rotate(270 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.75' transform='rotate(300 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.85' transform='rotate(330 60 60)'/%3E%3C/svg%3E");
  background-position: 50%;
  background-size: 100%;
  background-repeat: no-repeat;
}

.swiper-lazy-preloader-white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 120 120' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' stroke='%23fff' stroke-width='11' stroke-linecap='round' d='M60 7v20'/%3E%3C/defs%3E%3Cuse xlink:href='%23a' opacity='.27'/%3E%3Cuse xlink:href='%23a' opacity='.27' transform='rotate(30 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.27' transform='rotate(60 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.27' transform='rotate(90 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.27' transform='rotate(120 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.27' transform='rotate(150 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.37' transform='rotate(180 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.46' transform='rotate(210 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.56' transform='rotate(240 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.66' transform='rotate(270 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.75' transform='rotate(300 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.85' transform='rotate(330 60 60)'/%3E%3C/svg%3E");
}

@-webkit-keyframes swiper-preloader-spin {
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes swiper-preloader-spin {
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
}

.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  -webkit-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.swiper-container-fade .swiper-slide {
  pointer-events: none;
  -webkit-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
}

.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-container-cube {
  overflow: visible;
}

.swiper-container-cube .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1;
  visibility: hidden;
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  width: 100%;
  height: 100%;
}

.swiper-container-cube .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-container-cube.swiper-container-rtl .swiper-slide {
  -webkit-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0;
}

.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-next,
.swiper-container-cube .swiper-slide-next + .swiper-slide,
.swiper-container-cube .swiper-slide-prev {
  pointer-events: auto;
  visibility: visible;
}

.swiper-container-cube .swiper-slide-shadow-bottom,
.swiper-container-cube .swiper-slide-shadow-left,
.swiper-container-cube .swiper-slide-shadow-right,
.swiper-container-cube .swiper-slide-shadow-top {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.swiper-container-cube .swiper-cube-shadow {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.6;
  -webkit-filter: blur(50px);
  filter: blur(50px);
  z-index: 0;
}

.swiper-container-flip {
  overflow: visible;
}

.swiper-container-flip .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1;
}

.swiper-container-flip .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-container-flip .swiper-slide-active,
.swiper-container-flip .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-container-flip .swiper-slide-shadow-bottom,
.swiper-container-flip .swiper-slide-shadow-left,
.swiper-container-flip .swiper-slide-shadow-right,
.swiper-container-flip .swiper-slide-shadow-top {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.swiper-container-coverflow .swiper-wrapper {
  -ms-perspective: 1200px;
}

.nuxt-progress {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 2px;
  width: 0;
  opacity: 1;
  transition: width 0.1s, opacity 0.4s;
  background-color: #fff;
  z-index: 999999;
}

.nuxt-progress.nuxt-progress-notransition {
  transition: none;
}

.nuxt-progress-failed {
  background-color: red;
}

.full-wrap {
  width: 100%;
  height: 100%;
  position: relative;
}

.content {
  max-width: 900px;
  margin: 0 auto;
  line-height: 2;
  padding: 66px 5% 0;
}

@media screen and (max-width: 600px) {
  .news_header_title {
    font-size: 22px !important;
  }
}

.content img {
  max-width: 100%;
  text-align: center;
  margin: 10px auto;
}

.content-nav {
  font-size: 12px;
  margin: 30px 0;
  color: #888;
}

.content-nav a {
  text-decoration: none;
  color: #888;
}

.news_header_top .news_header_title {
  font-size: 28px;
  line-height: 1.5;
  color: #333;
  font-weight: 500;
}

.news_header_top .news_header_date {
  font-size: 12px;
  color: #888;
  margin-top: 10px;
  margin-bottom: 20px;
}

.header[data-v-1c1fd050] {
  height: 70px;
  position: fixed;
  z-index: 999;
  width: 100%;
}

.head[data-v-1c1fd050] {
  width: 92%;
  margin: 0 auto;
  position: relative;
}

.logo[data-v-1c1fd050] {
  float: left;
  width: 152px;
}

.logo[data-v-1c1fd050],
.logo-wrap[data-v-1c1fd050] {
  height: 70px;
  line-height: 70px;
}

.logo-wrap[data-v-1c1fd050],
.logo-wrap img[data-v-1c1fd050] {
  display: inline-block;
}

.logo-wrap img[data-v-1c1fd050] {
  height: 28px;
  width: 152px;
  margin-top: 21px;
}

.logo-wrap .default-logo[data-v-1c1fd050] {
  display: inline-block;
}

.logo-wrap .activ-logo[data-v-1c1fd050] {
  display: none;
}

.nav[data-v-1c1fd050] {
  float: right;
  text-align: center;
  position: relative;
}

.nav .nav-wrap[data-v-1c1fd050] {
  line-height: 70px;
}

.nav .nav-wrap > li:hover .pssubnav[data-v-1c1fd050] {
  display: block;
}

.pssubnav[data-v-1c1fd050] {
  position: absolute;
  top: 55px;
  left: -16px;
  width: 114px;
  background: #fff;
  border-radius: 10px;
  padding: 10px 0;
  z-index: 99999;
  display: none;
  overflow: hidden;
  box-shadow: 0 0 5px 0 #dadfe4;
}

.pssubnav li[data-v-1c1fd050] {
  overflow: hidden;
  font-size: 0;
  float: left;
}

.pssubnav li[data-v-1c1fd050],
.pssubnav li a[data-v-1c1fd050] {
  width: 100%;
  line-height: 33px;
  height: 33px;
}

.pssubnav li a[data-v-1c1fd050] {
  color: #333;
  text-align: center;
  font-size: 13px;
  display: inline-block;
}

.pssubnav li a[data-v-1c1fd050]:active,
.pssubnav li a[data-v-1c1fd050]:hover {
  color: #2b69fb;
  background-color: #f0f0f0;
}

.header-right[data-v-1c1fd050] {
  float: right;
  width: auto;
  height: 70px;
  line-height: 70px;
  margin-left: 60px;
}

.header-right a[data-v-1c1fd050] {
  margin: 0 8px;
  text-align: center;
}

.home-login[data-v-1c1fd050] {
  display: inline-block;
  width: 56px;
  height: 33px;
  line-height: 33px;
  border-radius: 5px;
  border: 1px solid #fff;
  color: #fff;
  font-size: 14px;
}

.home-login[data-v-1c1fd050]:hover {
  background: #fff;
  color: #2b69fb;
  font-weight: 400;
}

.free-use[data-v-1c1fd050] {
  display: inline-block;
  width: auto;
  padding: 0 16px;
  height: 33px;
  line-height: 33px;
  border-radius: 5px;
  border: 1px solid #fff;
  color: #fff;
  font-size: 14px;
}

.free-use[data-v-1c1fd050]:hover {
  background: #fff;
  color: #2b69fb;
  font-weight: 400;
}

.nav-wrap > li[data-v-1c1fd050] {
  margin: 0 25px;
  line-height: 70px;
  position: relative;
}

.nav-wrap a[data-v-1c1fd050] {
  height: 55px;
  text-decoration: none;
  color: #fff;
  font-size: 14px;
  display: inline-block;
}

.product-server .down-arrow[data-v-1c1fd050] {
  display: inline-block;
  height: 10px;
  width: 10px;
  vertical-align: middle;
}

.nav-wrap a.active[data-v-1c1fd050],
.nav-wrap a[data-v-1c1fd050]:hover {
  color: #fff;
  font-weight: 600;
  border-bottom: 3px solid #fff;
}

.product-server a.active[data-v-1c1fd050],
.product-server a[data-v-1c1fd050]:hover {
  color: #fff;
  font-weight: 600;
  border-bottom: none;
}

.mobile-menu[data-v-1c1fd050] {
  display: none;
  float: right;
  height: 80px;
  line-height: 80px;
}

.mobile-menu img[data-v-1c1fd050] {
  display: inline-block;
  height: 20px;
  width: 20px;
  vertical-align: middle;
}

.show-active[data-v-1c1fd050] {
  display: inline-block !important;
}

.hide-active[data-v-1c1fd050] {
  display: none !important;
}

.active-nav[data-v-1c1fd050] {
  width: 100%;
  background: #fff;
  box-shadow: 0 0 3px 0 #f1f5f9;
}

.active-nav .show-active[data-v-1c1fd050] {
  display: none !important;
}

.active-nav .hide-active[data-v-1c1fd050] {
  display: inline-block !important;
}

.active-nav .nav-wrap a[data-v-1c1fd050] {
  height: 55px;
  text-decoration: none;
  color: #333;
  display: inline-block;
}

.active-nav .nav-wrap a.active[data-v-1c1fd050],
.active-nav .nav-wrap a[data-v-1c1fd050]:hover {
  color: #2b69fb;
  font-weight: 600;
  border-bottom: 3px solid #2b69fb;
}

.active-nav .header-right[data-v-1c1fd050] {
  float: right;
  width: auto;
  height: 70px;
  line-height: 70px;
}

.active-nav .header-right a[data-v-1c1fd050] {
  margin: 0 8px;
  text-align: center;
}

.active-nav .product-server a.active[data-v-1c1fd050],
.active-nav .product-server a[data-v-1c1fd050]:hover {
  color: #2b69fb;
  font-weight: 600;
  border-bottom: none;
}

.active-nav .home-login[data-v-1c1fd050] {
  display: inline-block;
  width: 56px;
  height: 33px;
  line-height: 33px;
  border-radius: 5px;
  border: 1px solid #2b69fb;
  color: #2b69fb;
}

.active-nav .home-login[data-v-1c1fd050]:hover {
  background: #2b69fb;
  color: #fff;
  font-weight: 400;
}

.active-nav .free-use[data-v-1c1fd050] {
  display: inline-block;
  width: auto;
  padding: 0 16px;
  height: 33px;
  line-height: 33px;
  border-radius: 5px;
  border: 1px solid #2b69fb;
  color: #2b69fb;
}

.active-nav .free-use[data-v-1c1fd050]:hover {
  background: #2b69fb;
  color: #fff;
  font-weight: 400;
}

@media screen and (max-width: 1050px) {
  .nav-wrap > li[data-v-1c1fd050] {
    margin: 0 11px;
  }

  .header-right[data-v-1c1fd050] {
    margin-left: 40px;
  }
}

@media screen and (max-width: 900px) {
  .nav-wrap > li[data-v-1c1fd050] {
    margin: 0 10px;
  }

  .header-right[data-v-1c1fd050] {
    margin-left: 20px;
  }
}

@media screen and (max-width: 850px) {
  .header[data-v-1c1fd050] {
    height: 70px;
    position: fixed;
    z-index: 999;
    width: 100%;
  }

  .head[data-v-1c1fd050] {
    width: 92%;
    margin: 0 auto;
    position: relative;
  }

  .logo[data-v-1c1fd050] {
    float: left;
    overflow: hidden;
  }

  .nav[data-v-1c1fd050] {
    float: right;
    text-align: center;
    position: relative;
    display: none;
  }

  .nav .nav-wrap[data-v-1c1fd050] {
    line-height: 80px;
  }

  .mobile-menu[data-v-1c1fd050] {
    display: block;
    height: 80px;
    line-height: 70px;
  }

  .mobile-menu img[data-v-1c1fd050] {
    width: 25px;
    height: 20px;
  }

  .header-right[data-v-1c1fd050] {
    display: none;
  }
}

.slidebar-wrap {
  background: rgba(0, 0, 0, 0.3);
}

.slidebar-wrap,
.slidebar-wrap .slidebar-content {
  position: fixed;
  right: 0;
  top: 0;
  width: 0;
  height: 100%;
  z-index: 999999;
}

.slidebar-wrap .slidebar-content {
  background: #fff;
  transition: width 0.5s;
}

.slidebar-wrap-active {
  width: 100%;
}

.slidebar-wrap-active .slidebar-content {
  transition: width 0.5s;
  position: fixed;
  right: 0;
  top: 0;
  width: 64%;
  height: 100%;
  background: #fff;
  z-index: 999999;
}

.slidebar-nav {
  width: 100%;
  padding: 12px 0;
}

.slidebar-nav li {
  text-align: left;
}

.slidebar-nav li,
.slidebar-nav li a {
  width: 100%;
  color: #051033;
  font-size: 16px;
}

.slidebar-nav li a {
  padding: 16px 24px;
  display: inline-block;
}

.slidebar-nav li .activeColor,
.slidebar-nav li a:active,
.slidebar-nav li a:hover {
  color: #2b69fb;
}

.slidebar-nav .p-s-default {
  display: inline-block;
  width: 100%;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAABJElEQVRIS+3UMU7DMBTG8b+dhnYpqoCRuQxICFTEwBkQQw/Awk16HwYWJpBYEUiIhQuwoxYESBD8kBGRkppguymdkjGx/cv77GfFgh+1YI8GnHviTaT/G+lSbzAycIyYo2xye1FL6w7W0kSdgrQ/xm/7cP9u1yvtYau3e6KQIfAqog+zyfVs6DeGnbsl8Jzp1jqPV08OSHdvNU0+z4HtmdECBrwI6iAb31zmabmntA7qwdwK899Y3lxJdcdGEl5pAFYN2i8xaCD2NxiKRmB+0IdGYmFgFWrMXX70fzuNVT0cfpdO7SnCA4qNGCy8wvLptX268/PK6TPf7RReYRFNOmci0gc9LDa1D4uvsLyiBkwIUhwTX2GsMDW+AWsG6E5vIp17pF+hPaQdeBF4oAAAAABJRU5ErkJggg==)
    no-repeat 100%;
  background-size: 16px 16px;
  background-position: 92% 13px;
}

.slidebar-nav .p-s-active {
  display: inline-block;
  width: 100%;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAABFklEQVRIS+3WsUoDMRzH8e8/nIIWtbj7Ai4iVFyFc3Jx8Xl8Exd3O0ifoVtVBAedHUWKLqfpJVLxqD3vrvePR6fLnOTD/5d/QoQlD1myRws2nngbaVmkBnAheasjjbq9I3B9EXmyaXLC28OrBlaBU0zwA6Dzg9xalxxr0NrgHOZ5RNgB1gEVWgvMVXZvU+LImD0Rd61FF4JFGO+jl2mk0dZBrEUrwSosa5QcemddEledaSlYBwtBC0ENpkX/gCGYBp0D/4PVRWfg9uFm5CbPAhvAd+tn3ah5SfLd6/FXk/HNWbbHrwp3V1e6a0OQD5v601BsVul+jJhLAxef49F5AaitI2z+wosftm35qhZsOtH2m9h4onwBYpGwHeK+154AAAAASUVORK5CYII=)
    no-repeat 100%;
  background-size: 16px 16px;
  background-position: 92% 16px;
}

.slidebar-nav .s-b-sub-nav {
  height: 0;
}

.slidebar-nav .s-b-sub-nav,
.slidebar-nav .s-b-sub-nav-active {
  width: 100%;
  overflow: hidden;
  text-align: center;
  background: #f7fafd;
}

.slidebar-nav .s-b-sub-nav-active {
  height: 100%;
  margin-top: 20px;
  transition: height 0.5s;
}

.about-us[data-v-1d7ec599] {
  width: 100%;
  height: auto;
  overflow: hidden;
  background: #fff;
}

.mobile-us-nav[data-v-1d7ec599],
.moblie-banner[data-v-1d7ec599] {
  display: none;
}

.ab-us-wrap[data-v-1d7ec599] {
  max-width: 1000px;
  min-height: 400px;
  overflow: hidden;
  position: relative;
  margin: 66px auto 0;
}

.ab-us-wrap .slid-left-bar[data-v-1d7ec599] {
  position: fixed;
  top: 120px;
  left: 50%;
  margin-left: -440px;
  width: 170px;
  height: 264px;
  background: #fff;
  box-shadow: 0 0 40px 0 #f1f5f9;
  border-radius: 5px;
  z-index: 99;
}

.ab-us-wrap .slide-left-wrap[data-v-1d7ec599] {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 14px 0;
}

.ab-us-wrap .slide-left-wrap li[data-v-1d7ec599] {
  display: inline-block;
  width: 100%;
  text-align: center;
}

.ab-us-wrap .slide-left-wrap li a[data-v-1d7ec599] {
  font-size: 16px;
  font-weight: 400;
  color: #222;
  line-height: 22px;
}

.ab-us-wrap .slide-left-wrap li .activeColor[data-v-1d7ec599],
.ab-us-wrap .slide-left-wrap li a[data-v-1d7ec599]:hover {
  color: #3170fb;
}

.ab-us-wrap .slide-right-wrap[data-v-1d7ec599] {
  max-width: 746px;
  height: auto;
  float: right;
  overflow: hidden;
  margin-top: 55px;
}

.ab-us-wrap .slide-right-wrap .slide-banner[data-v-1d7ec599] {
  display: inline-block;
  height: 129px;
  width: auto;
  margin-bottom: 32px;
}

.ab-us-wrap .ab-title[data-v-1d7ec599] {
  font-size: 24px;
  font-weight: 600;
  color: #051033;
  line-height: 33px;
  margin: 24px 0;
}

.ab-us-wrap .ab-company[data-v-1d7ec599] {
  width: 100%;
  margin-bottom: 40px;
}

.ab-us-wrap .ab-company .ab-desc[data-v-1d7ec599] {
  font-size: 16px;
  font-weight: 400;
  color: #051033;
  line-height: 28px;
}

.ab-us-wrap .team-view[data-v-1d7ec599] {
  width: 100%;
  overflow: hidden;
  margin-bottom: 40px;
}

.ab-us-wrap .team-view .team-content[data-v-1d7ec599] {
  width: 100%;
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  align-items: top;
  font-size: 16px;
}

.ab-us-wrap .team-view .team-content .t-c-item[data-v-1d7ec599] {
  width: 150px;
  text-align: left;
  overflow: hidden;
}

.ab-us-wrap
  .team-view
  .team-content
  .t-c-item
  .team-content-top[data-v-1d7ec599] {
  overflow: hidden;
  height: 32px;
  line-height: 28px;
  text-align: left;
}

.ab-us-wrap
  .team-view
  .team-content
  .t-c-item
  .team-content-top
  img[data-v-1d7ec599] {
  display: inline-block;
  height: 32px;
  width: 32px;
  vertical-align: middle;
}

.ab-us-wrap
  .team-view
  .team-content
  .t-c-item
  .team-content-top
  .t-c-title[data-v-1d7ec599] {
  display: inline-block;
  height: 32px;
  line-height: 32px;
  font-size: 16px;
  font-weight: 500;
  color: #051033;
  margin-bottom: 8px;
}

.ab-us-wrap .team-view .team-content .t-c-item .team-desc[data-v-1d7ec599] {
  font-size: 20px;
  font-weight: 400;
  color: #051033;
  line-height: 20px;
}

.ab-us-wrap .ab-partner[data-v-1d7ec599] {
  width: 100%;
  margin-bottom: 40px;
}

.ab-us-wrap .ab-partner .ab-p-content[data-v-1d7ec599] {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.ab-us-wrap .ab-partner .ab-p-content .ab-c-iteam[data-v-1d7ec599] {
  width: auto;
  text-align: center;
}

.ab-us-wrap .ab-partner .ab-p-content .ab-c-iteam dt img[data-v-1d7ec599] {
  display: inline-block;
  height: 68px;
  width: 68px;
}

.ab-us-wrap .ab-partner .ab-p-content .ab-c-iteam dd[data-v-1d7ec599] {
  font-size: 16px;
  font-weight: 400;
  color: #7388a5;
  line-height: 22px;
  padding: 16px 0;
}

.ab-us-wrap .ab-detail[data-v-1d7ec599] {
  width: 100%;
  margin-bottom: 60px;
}

.ab-us-wrap .ab-detail p[data-v-1d7ec599] {
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  color: #051033;
  line-height: 20px;
  margin-bottom: 8px;
}

.ab-us-wrap .ab-detail p span[data-v-1d7ec599]:first-child {
  margin-right: 16px;
}

@media screen and (min-width: 1001px) and (max-width: 1600px) {
  .ab-us-wrap[data-v-1d7ec599] {
    max-width: 1000px;
    min-height: 400px;
    overflow: hidden;
    position: relative;
    margin: 66px auto 0;
  }
}

@media screen and (max-width: 1000px) {
  .about-us[data-v-1d7ec599] {
    width: 100%;
    height: auto;
    overflow: hidden;
    background: #fff;
    padding-top: 70px;
  }

  .moblie-banner[data-v-1d7ec599] {
    display: block;
  }

  .moblie-banner img[data-v-1d7ec599] {
    width: 100%;
    height: auto;
  }

  .mobile-us-nav[data-v-1d7ec599] {
    display: block;
    width: 100%;
    height: 50px;
  }

  .mobile-us-nav .moblie-us-wrap[data-v-1d7ec599] {
    width: 92%;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .mobile-us-nav .moblie-us-wrap.fixed[data-v-1d7ec599] {
    position: fixed;
    z-index: 1;
    width: 100%;
    top: 70px;
    left: 0;
    right: 0;
    background: #fff;
  }

  .mobile-us-nav .moblie-us-wrap .m-u-w-conpany[data-v-1d7ec599],
  .mobile-us-nav .moblie-us-wrap .m-u-w-history[data-v-1d7ec599] {
    padding: 16px 0;
    font-size: 16px;
    color: #051033;
    font-weight: 600;
    flex: 1;
    text-align: center;
  }

  .mobile-us-nav .moblie-us-wrap .line[data-v-1d7ec599] {
    width: 1px;
    height: 16px;
    background: #d8d8d8;
  }

  .mobile-us-nav .moblie-us-wrap .m-u-w-conpany-active[data-v-1d7ec599] {
    color: #3170fb;
    font-weight: 600;
    border-bottom: 2px solid #3170fb;
  }

  .ab-us-wrap[data-v-1d7ec599] {
    max-width: 92%;
    min-height: 400px;
    overflow: hidden;
    position: relative;
    margin: 0 auto;
  }

  .ab-us-wrap .slid-left-bar[data-v-1d7ec599] {
    position: fixed;
    top: 120px;
    left: 5%;
    width: 170px;
    height: 264px;
    background: #fff;
    box-shadow: 0 0 40px 0 #f1f5f9;
    border-radius: 5px;
    z-index: 99;
    display: none;
  }

  .ab-us-wrap .slide-left-wrap[data-v-1d7ec599] {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    padding: 14px 0;
  }

  .ab-us-wrap .slide-left-wrap li[data-v-1d7ec599] {
    display: inline-block;
    width: 100%;
    text-align: center;
  }

  .ab-us-wrap .slide-left-wrap li a[data-v-1d7ec599] {
    font-size: 16px;
    font-weight: 400;
    color: #222;
    line-height: 22px;
  }

  .ab-us-wrap .slide-left-wrap li a[data-v-1d7ec599]:hover {
    color: #3170fb;
  }

  .ab-us-wrap .slide-right-wrap[data-v-1d7ec599] {
    width: 100%;
    height: auto;
    float: right;
    overflow: hidden;
    margin-top: 55px;
  }

  .ab-us-wrap .slide-right-wrap .slide-banner[data-v-1d7ec599] {
    display: inline-block;
    height: 129px;
    width: auto;
    margin-bottom: 32px;
    display: none;
  }

  .ab-us-wrap .ab-title[data-v-1d7ec599] {
    font-size: 24px;
    font-weight: 600;
    color: #051033;
    line-height: 33px;
    margin: 24px 0;
  }

  .ab-us-wrap .ab-company[data-v-1d7ec599] {
    width: 100%;
    margin-bottom: 40px;
  }

  .ab-us-wrap .ab-company .ab-desc[data-v-1d7ec599] {
    font-size: 16px;
    font-weight: 400;
    color: #051033;
    line-height: 28px;
  }

  .ab-us-wrap .team-view[data-v-1d7ec599] {
    width: 100%;
    overflow: hidden;
    margin-bottom: 20px;
  }

  .ab-us-wrap .team-view .team-content[data-v-1d7ec599] {
    width: 100%;
    overflow: hidden;
  }

  .ab-us-wrap .team-view .team-content .t-c-item[data-v-1d7ec599] {
    width: 100%;
    text-align: center;
    overflow: hidden;
    background: #f7fafd;
    margin-bottom: 20px;
    padding: 8px;
  }

  .ab-us-wrap
    .team-view
    .team-content
    .t-c-item
    .team-content-top[data-v-1d7ec599] {
    overflow: hidden;
    text-align: center;
  }

  .ab-us-wrap
    .team-view
    .team-content
    .t-c-item
    .team-content-top
    img[data-v-1d7ec599] {
    display: inline-block;
    height: 32px;
    width: 32px;
    vertical-align: middle;
  }

  .ab-us-wrap
    .team-view
    .team-content
    .t-c-item
    .team-content-top
    .t-c-title[data-v-1d7ec599] {
    display: inline-block;
    height: 32px;
    line-height: 32px;
    font-size: 16px;
    font-weight: 500;
    color: #051033;
    margin-bottom: 8px;
  }

  .ab-us-wrap .team-view .team-content .t-c-item .team-desc[data-v-1d7ec599] {
    font-size: 20x;
    font-weight: 400;
    color: #051033;
    line-height: 20px;
  }

  .ab-us-wrap .ab-partner[data-v-1d7ec599] {
    width: 100%;
    margin-bottom: 40px;
  }

  .ab-us-wrap .ab-partner .ab-p-content[data-v-1d7ec599] {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .ab-us-wrap .ab-partner .ab-p-content .ab-c-iteam[data-v-1d7ec599] {
    width: 33%;
    text-align: center;
  }

  .ab-us-wrap .ab-partner .ab-p-content .ab-c-iteam dt img[data-v-1d7ec599] {
    display: inline-block;
    height: 56px;
    width: 56px;
  }

  .ab-us-wrap .ab-partner .ab-p-content .ab-c-iteam dd[data-v-1d7ec599] {
    font-size: 14px;
    font-weight: 400;
    color: #7388a5;
    line-height: 22px;
    padding: 16px 0;
  }

  .ab-us-wrap .ab-detail[data-v-1d7ec599] {
    width: 100%;
    margin-bottom: 60px;
  }

  .ab-us-wrap .ab-detail p[data-v-1d7ec599] {
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    color: #051033;
    line-height: 20px;
    margin-bottom: 8px;
  }

  .ab-us-wrap .ab-detail p[data-v-1d7ec599],
  .ab-us-wrap .ab-detail p span[data-v-1d7ec599] {
    display: flex;
    flex-wrap: nowrap;
  }

  .ab-us-wrap .ab-detail p span[data-v-1d7ec599]:first-child {
    width: 40px;
    margin-right: 16px;
  }
}

.about-us {
  width: 100%;
  overflow: hidden;
}

.about-us #parentUs {
  height: 300px;
  width: 100%;
  background: #fff;
}

.about-us #parentUs .swiper-slide {
  width: 260px;
  height: 223px;
  text-align: center;
  background: #fff;
  transition: 0.2s;
  border-radius: 10px;
  transform: scale(0.9);
}

.about-us #parentUs .swiper-slide .h-silde-leftIcon {
  box-shadow: 0 0 40px 0 #f1f5f9;
  width: 100%;
  text-align: center;
  padding-top: 10px;
}

.about-us #parentUs .swiper-slide .h-silde-leftIcon img {
  display: inline-block;
  height: 143px;
  width: 202px;
}

.about-us #parentUs .swiper-slide .h-silde-leftIcon p {
  padding: 16px;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  color: #051033;
  line-height: 20px;
}

.about-us #parentUs .swiper-pagination-inner {
  max-width: 1000px;
  height: 100%;
  margin: 0 auto;
  position: relative;
}

.about-us #parentUs .swiper-pagination {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-25%);
  border-radius: none;
  text-align: left;
}

.about-us #parentUs .swiper-pagination-bullet {
  display: inline-block;
  width: 10px;
  height: 10px;
  background: #7388a5;
}

.about-us #parentUs .swiper-pagination-bullet-active {
  display: inline-block;
  width: 10px;
  height: 10px;
  background: #2b69fb;
}

.about-us #parentNew {
  display: none;
}

@media screen and (max-width: 1000px) {
  .about-us #parentUs {
    display: none;
  }

  .about-us #parentNew {
    display: inline-block;
    height: 300px;
    width: 100%;
    background: #fff;
    text-align: center;
  }

  .about-us #parentNew .swiper-slide {
    width: 100%;
    height: 223px;
    text-align: center;
    background: #fff;
    transition: 0.2s;
    border-radius: 10px;
    transform: scale(1);
  }

  .about-us #parentNew .swiper-slide .h-silde-leftIcon {
    box-shadow: 0 0 40px 0 #f1f5f9;
    width: 100%;
    text-align: center;
    padding-top: 10px;
  }

  .about-us #parentNew .swiper-slide .h-silde-leftIcon img {
    display: inline-block;
    height: 143px;
    width: 202px;
  }

  .about-us #parentNew .swiper-slide .h-silde-leftIcon p {
    padding: 16px;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #051033;
    line-height: 20px;
  }

  .about-us #parentNew .swiper-pagination-inner {
    max-width: 1000px;
    height: 100%;
    margin: 0 auto;
    position: relative;
  }

  .about-us #parentNew .swiper-pagination {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-25%);
    border-radius: none;
    text-align: left;
  }

  .about-us #parentNew .swiper-pagination-bullet {
    display: inline-block;
    width: 10px;
    height: 10px;
    background: #7388a5;
  }

  .about-us #parentNew .swiper-pagination-bullet-active {
    display: inline-block;
    width: 10px;
    height: 10px;
    background: #2b69fb;
  }
}

.timeline-wrap[data-v-1517d690] {
  overflow: hidden;
}

.timeline[data-v-1517d690] {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  background-color: inherit;
}

.timeline[data-v-1517d690]:after {
  content: "";
  position: absolute;
  width: 2px;
  background-color: #7388a5;
  left: 31px;
  top: 0;
  bottom: 65px;
  margin-left: -2px;
}

.entry[data-v-1517d690] {
  padding: 0 30px 32px;
  width: 50%;
  position: relative;
  background-color: inherit;
}

.entry--right[data-v-1517d690] {
  margin-left: 30px;
}

.entry__content[data-v-1517d690] {
  padding: 0 30px;
  background-color: #fff;
  position: relative;
}

.entry__content h2[data-v-1517d690] {
  width: 490px;
  font-size: 16px;
  font-weight: 500;
  color: #051033;
  line-height: 22px;
}

.entry__content p[data-v-1517d690] {
  font-size: 16px;
  font-weight: 400;
  color: #7388a5;
  line-height: 22px;
}

.entry[data-v-1517d690]:before {
  content: " ";
  height: 0;
  position: absolute;
  top: 20px;
  width: 0;
  z-index: 1;
}

.entry--left[data-v-1517d690]:before {
  right: 22px;
  border: solid transparent;
  border-left: solid #fff;
  border-width: 8px 0 8px 8px;
}

.entry--right[data-v-1517d690]:before {
  left: 22px;
  margin-left: 0;
  border: solid transparent;
  border-right: solid #fff;
  border-width: 8px 8px 8px 0;
}

.entry[data-v-1517d690]:after {
  content: "";
  position: absolute;
  width: 11px;
  height: 11px;
  right: -3px;
  background-color: #7388a5;
  top: 0;
  border-radius: 50%;
  z-index: 1;
}

.entry--right[data-v-1517d690]:after {
  right: auto;
  left: -5px;
}

@media (max-width: 1000px) {
  .timeline-wrap[data-v-1517d690] {
    overflow: hidden;
  }

  .timeline[data-v-1517d690] {
    position: relative;
    max-width: 800px;
    margin: 0 auto;
    background-color: inherit;
  }

  .timeline[data-v-1517d690]:after {
    content: "";
    position: absolute;
    width: 2px;
    background-color: #7388a5;
    left: 31px;
    top: 0;
    bottom: 65px;
    margin-left: -2px;
  }

  .entry[data-v-1517d690] {
    padding: 0 30px 32px;
    width: 100%;
    position: relative;
    background-color: inherit;
  }

  .entry--right[data-v-1517d690] {
    margin-left: 30px;
  }

  .entry__content[data-v-1517d690] {
    padding: 0 30px;
    background-color: #fff;
    position: relative;
  }

  .entry__content h2[data-v-1517d690] {
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    color: #051033;
    line-height: 22px;
  }

  .entry__content p[data-v-1517d690] {
    font-size: 16px;
    font-weight: 400;
    color: #7388a5;
    line-height: 22px;
  }

  .entry[data-v-1517d690]:before {
    content: " ";
    height: 0;
    position: absolute;
    top: 20px;
    width: 0;
    z-index: 1;
  }

  .entry--left[data-v-1517d690]:before {
    right: 22px;
    border: solid transparent;
    border-left: solid #fff;
    border-width: 8px 0 8px 8px;
  }

  .entry--right[data-v-1517d690]:before {
    left: 22px;
    margin-left: 0;
    border: solid transparent;
    border-right: solid #fff;
    border-width: 8px 8px 8px 0;
  }

  .entry[data-v-1517d690]:after {
    content: "";
    position: absolute;
    width: 11px;
    height: 11px;
    right: -3px;
    background-color: #7388a5;
    top: 0;
    border-radius: 50%;
    z-index: 1;
  }

  .entry--right[data-v-1517d690]:after {
    right: auto;
    left: -5px;
  }
}

.full-screen {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 99;
}

.full-screen .full-s-wrap {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.full-screen .f-s-dialog {
  width: 493px;
  height: 533px;
  position: absolute;
  top: 50%;
  left: 50%;
  box-shadow: 0 0 24px 0 rgba(31, 31, 31, 0.5);
  border-radius: 10px;
  background: #fff;
  z-index: 999;
  transform: translate(-50%, -50%);
}

.full-screen .f-s-dialog .f-s-d-top {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  height: 163px;
  background: url(../../assets/images/e062776.png) no-repeat;
  background-size: 100% 100%;
}

.full-screen .f-s-dialog .f-s-d-top p {
  font-size: 16px;
  font-weight: 400;
  color: #051033;
  line-height: 22px;
}

.full-screen .f-s-dialog .f-s-d-top h3 {
  font-size: 32px;
  font-weight: 600;
  color: #051033;
  line-height: 45px;
}

.full-screen .f-s-dialog .f-s-d-top .popcloseBtn {
  display: inline-block;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
}

.f-s-d-bottom {
  width: 100%;
  overflow: hidden;
  text-align: center;
}

.f-s-d-bottom p {
  font-size: 16px;
  font-weight: 400;
  color: #051033;
  line-height: 22px;
}

.f-s-d-bottom p:first-child {
  margin-top: 24px;
}

.f-s-d-bottom .contactwx {
  display: inline-block;
  height: 218px;
  width: 218px;
}

.f-s-d-bottom .contactbutton {
  display: inline-block;
  height: 38px;
  width: auto;
  cursor: pointer;
}

.slidebar-p {
  position: fixed;
  right: 20px;
  bottom: 120px;
  width: 156px;
  height: auto;
  overflow: hidden;
  z-index: 9999;
  text-align: center;
  background: none;
}

.slide-top {
  width: 100%;
  height: 217px;
  background-size: 100% 100%;
  position: relative;
}

.slide-top p {
  font-size: 12px;
  font-weight: 400;
  color: #fff;
  line-height: 17px;
  margin-top: 16px;
}

.server-head {
  text-align: center;
}

.server-head img {
  display: inline-block;
  width: 72px;
  height: auto;
  margin-top: 24px;
}

.m-p-number a {
  height: 22px;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

.concatus-btn,
.m-p-number a {
  display: inline-block;
  color: #fff;
}

.concatus-btn {
  text-align: center;
  width: 108px;
  height: 32px;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 400;
  line-height: 32px;
  border: 1px solid #fff;
  margin-top: 16px;
}

.moblie-btn {
  display: none;
}

.closebtn {
  display: inline-block;
  height: 16px;
  width: 16px;
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
}

.slide-wechat {
  width: 100%;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 0 40px 0 #f1f5f9;
  border-radius: 10px;
  padding: 10px 0 8px;
  margin: 8px 0;
}

.slide-wechat img {
  display: inline-block;
  width: 96px;
  height: 96px;
}

.slide-wechat p {
  font-size: 12px;
  color: #051033;
  line-height: 17px;
  margin: 8px;
}

.gotop,
.slide-wechat p {
  font-weight: 400;
  text-align: center;
}

.gotop {
  display: inline-block;
  line-height: 45px;
  width: 156px;
  height: 45px;
  background: #55a1fd;
  border-radius: 10px;
  color: #fff;
  font-size: 14px;
}

.moblie-slider {
  display: none;
}

@media screen and (max-width: 1000px) {
  .slidebar-p {
    position: fixed;
    right: 0;
    bottom: 10px;
    width: 30px;
    height: auto;
    overflow: scroll;
    z-index: 9999;
    text-align: center;
    background: red;
  }

  .pc-slider {
    display: none;
  }

  .moblie-slider {
    display: block;
    position: fixed;
    right: 0;
    top: 50%;
    width: 74px;
    overflow: hidden;
    z-index: 9999;
    text-align: center;
    background: none;
    height: auto;
  }

  .moblie-slider img {
    display: inline-block;
    width: 74px;
    height: auto;
  }

  .moblie-slider img:nth-child(2) {
    transform: translateY(-17px);
  }
}

.footer[data-v-38cb5c44] {
  width: 100%;
  height: auto;
  background: #061845;
  padding: 40px 0;
}

.footer .footer-wrap[data-v-38cb5c44] {
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
}

.footer .footer-wrap .footer-wrap-top[data-v-38cb5c44] {
  font-size: 16px;
  color: #fff;
  line-height: 22px;
}

.footer .footer-wrap .weixinNumber[data-v-38cb5c44] {
  display: inline-block;
  width: 144px;
  height: 144px;
  margin: 32px 0;
}

.footer .footer-wrap .footer-bottom[data-v-38cb5c44] {
  width: 51.8%;
  font-size: 16px;
  color: hsla(0, 0%, 100%, 0.6);
  line-height: 22px;
  margin: 0 auto 20px;
}

.footer .footer-wrap .footer-bottom .footer-left[data-v-38cb5c44] {
  float: left;
  overflow: hidden;
}

.footer .footer-wrap .footer-bottom .footer-right[data-v-38cb5c44] {
  float: right;
  overflow: hidden;
}

.footer .footer-wrap .footer-bottom .footer-right a[data-v-38cb5c44] {
  color: hsla(0, 0%, 100%, 0.6);
}

@media screen and (max-width: 1000px) {
  .footer[data-v-38cb5c44] {
    width: 100%;
    height: auto;
    background: #061845;
    padding: 40px 0;
  }

  .footer .footer-wrap[data-v-38cb5c44] {
    max-width: 1000px;
    margin: 0 auto;
    text-align: center;
  }

  .footer .footer-wrap .footer-wrap-top[data-v-38cb5c44] {
    font-size: 16px;
    color: #fff;
    line-height: 22px;
  }

  .footer .footer-wrap .weixinNumber[data-v-38cb5c44] {
    display: inline-block;
    width: 144px;
    height: 144px;
    margin: 32px 0;
  }

  .footer .footer-wrap .footer-bottom[data-v-38cb5c44] {
    width: 92%;
    font-size: 12px;
    color: hsla(0, 0%, 100%, 0.6);
    line-height: 22px;
    margin: 0 auto 20px;
  }

  .footer .footer-wrap .footer-bottom .footer-left[data-v-38cb5c44],
  .footer .footer-wrap .footer-bottom .footer-right[data-v-38cb5c44] {
    width: 100%;
    text-align: center;
    overflow: hidden;
  }
}
</style>
